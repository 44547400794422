import "./App.css";
import linkedinLogo from "./images/768px-LinkedIn_logo.png"
import githubLogo from "./images/githubLogo.png"
import myResume from "./images/myResume.png"

function App() {
  return (
    <div className="mainPage">
      <div className="flexContainer">
        <div>
          <p>
            Hi,
            <br />
            <br />
            I'm <span className="name">Eli Robbins</span>, a software developer
            from <br />
            Washington, D.C.
          </p>
          <a href="https://www.linkedin.com/in/eli-robbins/">
            <img className="logo" alt="LinkedIn" src={linkedinLogo} />
          </a>
          <a href="https://github.com/erobbi">
            <img className="logo" alt="GitHub" src={githubLogo} />
          </a>
          <a href="https://drive.google.com/file/d/1JOthFRFVB_Gt0cZGdBsKADTfLrzWPmRn/view?usp=sharing">
            <img className="logo" alt="my resume" src={myResume} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
